<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $route.params.id }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <div id="container">
        <h1>Login</h1>
        <form action="#" @submit.prevent="submit">
          <ion-grid class="login-form">
            <ion-row>
              <ion-col>
                <ion-item lines="none">
                  <ion-label class="standard-label" position="stacked">Email</ion-label>
                  <ion-input class="standard-input" type="email" name="email" v-model="form.email" />
                </ion-item>
                <ion-item lines="none">
                  <ion-label class="standard-label" position="stacked">Password</ion-label>
                  <ion-input class="standard-input" type="password" name="password" v-model="form.password" />
                </ion-item>

                <div class="forgotten"><a target="_blank" href="https://dashboard.brightchecker.com/forgot-password">Forgotten your password?</a></div>

                <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
                <ion-button color="secondary" fill="solid" class="login-btn" type="submit">Login</ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
  IonLabel, IonInput, IonItem, IonButton, IonCol, IonRow, IonGrid
} from '@ionic/vue';
import { mapActions } from 'vuex'
import loadsData from "@/mixins/loads-data";

export default {
  mixins: [
    loadsData,
  ],
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton, IonCol, IonRow, IonGrid,
    IonLabel, IonInput, IonItem
  },
  data () {
    return {
      form: {
        email: '',
        password: '',
      },
      errorMessage: ''
    }
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn',
      getMe: 'brightchecker/me',
    }),
    async submit () {
      await this.loadingTriggered();
      this.errorMessage = "";
      this.signIn(this.form).then(
        () => {
          this.getMe().then(() => {
            this.closeModal();
            this.$router.push({ path: "/" })
          })
        },
        ({ response: { data } }) => {
          this.closeModal();
          this.errorMessage = data.message
            ? data.message
            : "There was an error logging in with those details."
        },
      );
    },
  }
}
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

h1 {
  text-transform: uppercase;
}

.login-btn {
  margin-top: 20px;
}

.forgotten {
  margin-top: 20px;
  margin-left: 25px;
  margin-right: 25px;
  color: #373636;
}

.error-message {
  margin-top: 20px;
  margin-left: 25px;
  margin-right: 25px;
  color: #b41f1f;
}
</style>
